import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const defaultSettings = {
  autoplay: true,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}

const SliderContainer = ({settings = {}, children }) => {
  return (
    <Slider {...defaultSettings} {...settings}>
      {children}
    </Slider>
  )
}

export default SliderContainer
